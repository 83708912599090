import React from "react";
import styled from "@emotion/styled";
import { graphql, type PageProps } from "gatsby";

import breakpoints from "../utils/breakpoints";

const AboutPageStyles = styled.div`
  margin: 1rem;

  .about-contents {
    max-width: ${breakpoints.values.lg}px;
    margin: auto;
    margin-bottom: 40px;
  }
`;

type DataProps = {
  sanityAbout: {
    content_ja: string;
    content_en: string;
  };
};

export default function AboutPage(props: PageProps<DataProps>) {
  return (
    <AboutPageStyles>
      <div className="about-contents">This page is still being built.</div>
    </AboutPageStyles>
  );
}

export const query = graphql`
  query {
    sanityAbout {
      content_en
      content_ja
    }
  }
`;

export { default as Head } from "../core/Head";
